<template>
  <span
    class=" d-flex align-center"
    :class="
      domain.ssl_order_status == 'failed' ? 'error--text' : 'warning--text'
    "
  >
    {{ statusText }}
    <v-menu
      transition="custom-tooltip"
      open-delay="150"
      bottom
      z-index="99"
      max-width="785px"
      min-width="785px"
      content-class="v-tooltip"
      offset-overflow
      nudge-bottom="24px"
      :nudge-left="$vuetify.rtl ? '-350px' : '350px'"
      origin="top center"
      open-on-hover
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="domain.ssl_order_status == 'failed'"
          x-small
          icon
          text
          color="error"
          v-on="on"
          v-bind="attrs"
        >
          <v-icon color="error" size="20"> $alertwarning </v-icon>
        </v-btn>
        <v-btn
          v-else
          x-small
          icon
          text
          color="warning"
          v-on="on"
          v-bind="attrs"
        >
          <!-- <v-icon color="warning" size="20"> $alertwarning </v-icon> -->
          <v-progress-circular
            indeterminate
            color="warning"
            size="20"
          ></v-progress-circular>
        </v-btn>
      </template>
      <pending-ssl-tooltip :domain="domain" />
    </v-menu>
  </span>
</template>

<script>
import PendingSslTooltip from "./PendingSslTooltip.vue";

export default {
  components: { PendingSslTooltip },
  props: {
    domain: Object,
  },
  computed: {
    statusText() {
      switch (this.domain.ssl_order_status) {
        case "awaiting_dns_propagation":
          return this.$t('message.status.dns.awaitingDnsPropagation');
        case "failed":
          return this.$t('message.status.failed');
        case "pending":
        case "in_progress":
          return this.$t('message.status.inProgress');
        default:
          return this.$t('message.status.unknown');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>