<template>
  <div class="px-6 py-6">
    <div
      class="alert alert--error alert--outlined w-100 mb-0"
      border="left"
      type="error"
      v-if="domain.ssl_order_status == 'failed'"
    >
      <v-icon color="error" size="24" class="mr-3">$alertwarning</v-icon>
      <span class="p-3">
        <i18next :translation="$t('tooltip.hosting.dns.status.failed')">
          <template #domain>
            <b>{{ domain.domain }}</b>
          </template>
        </i18next>
      </span>
    </div>
    <div
      class="alert alert--warning alert--outlined w-100"
      border="left"
      type="warning"
      v-else-if="domain.ssl_order_status == 'awaiting_dns_propagation'"
    >
      <v-icon color="warning" size="24" class="mr-3">$alertwarning</v-icon>
      <span class="p-3">
         <i18next :translation="$t('tooltip.hosting.dns.status.awaiting')">
          <template #domain>
            <b>{{ domain.domain }}</b>
          </template>
        </i18next>
      </span>
    </div>
    <div
      class="alert alert--warning alert--outlined w-100"
      border="left"
      type="warning"
      v-else-if="['pending', 'in_progress'].includes(domain.ssl_order_status)"
    >
      <v-icon color="warning" size="24" class="mr-3">$alertwarning</v-icon>
      <span class="p-3">
       <i18next :translation="$t('tooltip.hosting.dns.status.pending.alert')">
          <template #domain>
            <b>{{ domain.domain }}</b>
          </template>
        </i18next>
      </span>
    </div>

    <ul
      class="mt-4"
      v-if="domain.ssl_order_status == 'awaiting_dns_propagation'"
    >
      <li class="p-3">
        {{ $t('tooltip.hosting.dns.status.pending.list.first') }}
      </li>
      <li class="p-3">
        <b>{{ $t('tooltip.hosting.dns.status.pending.list.second') }}</b>
      </li>
      <li class="p-3">
        {{ $t('tooltip.hosting.dns.status.pending.list.third') }}
      </li>
    </ul>
    <ul
      class="mt-4"
      v-else-if="['pending', 'in_progress'].includes(domain.ssl_order_status)"
    >
      <li class="p-3">
        {{ $t('tooltip.hosting.dns.status.pending.list.first') }}
      </li>
      <li class="p-3">
        {{ $t('tooltip.hosting.dns.status.pending.list.second') }}
      </li>
      <li class="p-3">
        {{ $t('tooltip.hosting.dns.status.pending.list.third') }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    domain: Object,
  },
};
</script>

<style lang="scss" scoped>
ul {
  li {
    padding-left: 18px;
    position: relative;
    &::before {
      position: absolute;
      content: "";
      width: 6px;
      height: 6px;
      border-radius: 100%;
      top: calc(50% - 3px);
      left: 0;
      background-color: map-get($primary, base);
    }
  }
}
</style>